import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class SupportAndDownload {
  @JsonProperty("info_download_id") id!: number

  @JsonProperty() title!: string

  @JsonProperty() link!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
